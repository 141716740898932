
import productDataService from '@/services/productDataService'
import {
  IProductWarehouse,
  IProductVariant,
  spanMethod,
  IWarehouseInStock,
  SummaryInStock,
} from '@/interfaces/product-warehouse'
import { defineComponent, ref, watch, onMounted } from 'vue'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useNotification } from '@/composables'
import { ElMessageBox } from 'element-plus'
import ImageViewDialog from './ImageViewDialog.vue'
import { Timer } from '@element-plus/icons'
import type { ElTable } from 'element-plus'

export default defineComponent({
  name: 'List',
  props: {},
  components: { ImageViewDialog, Timer },

  setup(props, context) {
    const state = productDataService.state
    const tableRef = ref<InstanceType<typeof ElTable>>()

    const { error, success } = useNotification()


    const deleteProduct = async (id: string | string[]): Promise<void> => {
      ElMessageBox.confirm(`Are you sure to delete this Product?`)
        .then(async () => {
          const { data, status } = await productDataService.deleteById(id)
          if (status === 200) {
            success('Deleted')
            context.emit('callback')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          error(err)
        })
    }
    const detailProduct = async (id: string): Promise<void> => {
      state.isSubmited = productDataService.ACTION.DETAIL
      state.product = {
        ...state.products.find((v: any) => v._id === id),
      } as IProductWarehouse
      state.product.variants = state.product.variants.map(
        (v: IProductVariant) => ({
          ...v,
          warehouse: 'Warehouse',
          available: 'Available',
          unavailable: 'UnAvailable',
          children: [
            {
              title: '123',
              sku: '456',
            },
          ],
        })
      )
      state.catalogId = state.product.catalogId
      state.isContentShown = true
      state.formTitle = state.product.title
    }

    let isLoading = false
    const imageViewDialog = ref<InstanceType<typeof ImageViewDialog>>()
    const onExpandChange = (row: any, expanded: any): void => {
      if (expanded.length === 0) {
        return
      }
      isLoading = true
      const warehouseDataTest: IWarehouseInStock[] = [
        {
          warehouseId: 1,
          warehouseName: 'US warehouse',
          available: '200',
          unavailable: '100',
        },
        {
          warehouseId: 2,
          warehouseName: 'Amazon warehouse',
          available: '300',
          unavailable: '300',
        },
      ]

      state.productDetail = { ...expanded[0] } as IProductWarehouse
      state.productDetail.variants = [
        ...state.productDetail.variants.map((v: IProductVariant) => ({
          ...v,
          warehouseName: warehouseDataTest,
        })),
      ]
      if (expanded.length > 1) {
        tableRef.value!.toggleRowExpansion(expanded.shift(), false)
      }
      isLoading = false
    }
    const Preview = (path: string): void => {
      path ? imageViewDialog.value?.view(path) : error('URL not found')
    }
    const objectSpanMethod = (row: any) => {
      if (row.columnIndex === 5) {
        //Merge from col 1->3
        let span: spanMethod = {
          rowspan: 1,
          colspan: 3,
        }
        return span
      }
    }
    const editProduct = (id: string) => {
      context.emit('onEdit', id)
    }
    const summaryInStock = (param: SummaryInStock) => {
      const { columns, data } = param
      const sums: string[] = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total Cost'
          return
        }
        const values = data.map((item: any) => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = `$ ${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            }
            return prev
          }, 0)}`
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }

    return {
      state,
      moment,
      objectSpanMethod,
      Preview,
      imageViewDialog,
      onExpandChange,
      deleteProduct,
      detailProduct,
      editProduct,
      isLoading,
      tableRef,
      summaryInStock,
    }
  },
})
