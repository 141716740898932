import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_table = _resolveComponent("filter-table")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_product_list = _resolveComponent("product-list")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!
  const _component_Create = _resolveComponent("Create")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_section_layout_content, _mergeProps(_ctx.content, { onActionCreate: _ctx.openCreateModal }), {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_filter_table),
            _createVNode(_component_el_col, {
              span: 25,
              class: "mb-4 flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  placeholder: "Catalog",
                  filterable: "",
                  remote: "",
                  class: "mx-2",
                  "reserve-keyword": "",
                  "default-first-option": "",
                  "remote-method": _ctx.getCatalog,
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeCatalog($event))),
                  modelValue: _ctx.catalogFilterValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.catalogFilterValue) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.catalogs, (catalog) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: catalog._id,
                        label: catalog.name,
                        value: catalog._id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["remote-method", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_product_list, {
          isLoading: _ctx.isLoading,
          documents: _ctx.state.products,
          onCallback: _ctx.onReloadProducts,
          onOnEdit: _ctx.editProduct
        }, null, 8, ["isLoading", "documents", "onCallback", "onOnEdit"]),
        _createVNode(_component_pagination, {
          total: _ctx.totalProduct,
          pagination: _ctx.state.params.page,
          "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.params.page) = $event)),
          limit: _ctx.state.params.limit,
          "onUpdate:limit": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.params.limit) = $event))
        }, null, 8, ["total", "pagination", "limit"])
      ]),
      _: 1
    }, 16, ["onActionCreate"]),
    _createVNode(_component_Create, { onCallback: _ctx.onReloadProducts }, null, 8, ["onCallback"])
  ], 64))
}