
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import ProductList from './components/ProductList.vue'
import Create from './components/Create.vue'
import { useRouter } from 'vue-router'
import productDataService from '@/services/productDataService'
import { AxiosResponse } from 'axios'
import { useNotification, useFetchData } from '@/composables'
import { IProductWarehouse } from '@/interfaces/product-warehouse'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import Pagination from '@/components/common/Pagination.vue'
export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    ProductList,
    Create,
    Pagination,
  },
  setup() {
    const router = useRouter()
    const breadcrumbs = ['Product Dropship', '']
    const titleCreate = 'Create Product'
    const { success, error } = useNotification()
    const state = productDataService.state
    const { getCatalogs } = userCatalogRepositories()
    const catalogFilterValue = ref('')
    const info = JSON.parse(localStorage.getItem('info') || '')
    const getCatalog = async (query?: string) => {
      console.log('info', info)
      
      const params = { ...state.params }
      params.basecost = info?.sellerTeam?.basecost.id
      if (query) params.search = query
      let res = await getCatalogs(params)
      state.catalogs = res as any[]
    }

    

    const content = computed(() => {
      return { breadcrumbs, titleCreate }
    })
    const totalProduct = ref()
    const count = async () => {
      totalProduct.value = (await productDataService.count())?.data
    }
    onMounted(() => {
      getCatalog()
      count()
      // getSellerTeam()
      state.isLoading = true
      getProducts()
    })

    const getSellerTeam = async () => {
      const params = reactive({sellerType:'default', active: true, page: 1, limit: 1000})
      const { documents, fetchData, isLoading, total } = useFetchData(
        'seller-teams',
        params
      )
      let sellerteams = documents as any

      console.log('seller team -->', sellerteams);
      
      fetchData()
    }

    const onReloadProducts = () =>{
        getProducts()    
    }
    const editProduct = (id:string) =>{
        state.isSubmited = productDataService.ACTION.UPDATE
        state.product = {
          ...state.products.find((v: any) => v._id === id),
        } as IProductWarehouse
        state.catalogId = state.product.catalogId
        state.isContentShown = true
        state.formTitle = state.product.title
    }

    const getProducts = async (): Promise<void> => {
      let data: AxiosResponse = await productDataService.getAll(state.params)
      const products = data.data.map((p: any) => ({
        ...p,
      }))
      state.products = products
      state.isLoading = false
    }

    // watch state params mục đích cho việc phân trang khi trong component pagination
    //có thay đổi về phân trang thì sẽ load lại theo thay đổi trong paginatin khai báo trong index.vue
    watch(state.params, getProducts)
    const onChangeCatalog = (catalogId: string) => {
      console.log('waiting API catalog filter:', catalogId)
    }
    const openCreateModal = () => {
      productDataService.clear()
      state.isSubmited = productDataService.ACTION.CREATE
      state.isContentShown = true
      state.formTitle = 'Create Product'
    }
    return {
      content,
      state,
      router,
      openCreateModal,
      onChangeCatalog,
      getCatalog,
      totalProduct,
      onReloadProducts,
      catalogFilterValue,
      editProduct
    }
  },
})
